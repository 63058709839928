import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import { Link } from "gatsby"

import RevealOnScroll from "../../_shared/RevealOnScroll"
import RoundedButton from "../../_shared/RoundedButton"
import MobileSiteSlider from "../../_shared/SiteSlider"

import CompanyLogo from "./CompanyLogo"

// ----------------------------------------------------------------------------

const AMAZON_COUNTRIES = [
  { code: "it", label: "IT" },
  { code: "fr", label: "FR" },
  { code: "es", label: "ES" },
  { code: "en-gb", label: "UK" },
  { code: "de", label: "DE" },
]
const EBAY_COUNTRIES = [
  { code: "it", label: "IT" },
  { code: "en-gb", label: "UK" },
  { code: "de", label: "DE" },
  { code: "en-us", label: "US" },
  { code: "de-ch", label: "CH" },
  { code: "en-au", label: "AU" },
  { code: "de-at", label: "AT" },
  { code: "fr", label: "FR" },
  { code: "es", label: "ES" },
]

//------------------------------------------------------------------------------

function MarketPlaceSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------
  const defaultIsMobile = matchMobileMediaQuery()

  const [isMobile, setisMobile] = useState(defaultIsMobile)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleWindowResize()
      window.addEventListener("resize", handleWindowResize)
    }

    return function cleanup() {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleWindowResize() {
    setisMobile(matchMobileMediaQuery())
  }

  function matchMobileMediaQuery() {
    const { theme } = props

    if (typeof window !== "undefined") {
      const mediaQuery = theme.mediaQueries && theme.mediaQueries.mobile

      const matchMediaString = `(max-width: ${mediaQuery})`

      const matchMedia = window.matchMedia(matchMediaString)

      if (matchMedia.matches) {
        return true
      }
    }

    return false
  }

  function renderSlide(index) {
    return SLIDES[index]
  }

  function renderMarketplaceCountries(countries) {
    return (
      countries &&
      countries.map((country) => {
        return (
          <div key={country.code} className="country-row">
            <img
              src={require(`../../../images/countries/${country.code}.svg`)}
            />{" "}
            <span>{country.label}</span>
          </div>
        )
      })
    )
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const SLIDES = [
    <div key={"amazon"} className="column amazon-column">
      <CompanyLogo logo={require("../../../images/home/amazon-logo.svg")} />

      <div className="countries-container">
        {renderMarketplaceCountries(AMAZON_COUNTRIES)}
      </div>
    </div>,

    <div key={"ebay"} className="column ebay-column">
      <CompanyLogo logo={require("../../../images/home/ebay-logo.svg")} />

      <div className="countries-container">
        {renderMarketplaceCountries(EBAY_COUNTRIES)}
      </div>
    </div>,
  ]

  return (
    <section className={props.className}>
      <h6>MARKETPLACE</h6>

      <div className="section-content-container">
        <div className="row main-row">
          {typeof window !== "undefined" && isMobile && (
            <div className="marketplace-slider-container">
              <MobileSiteSlider
                slides={SLIDES}
                renderSlide={renderSlide}
                theme="light"
              />
            </div>
          )}

          {typeof window !== "undefined" && !isMobile && (
            <>
              <div className="column amazon-column">
                <CompanyLogo
                  logo={require("../../../images/home/amazon-logo.svg")}
                />

                <div className="countries-container">
                  {renderMarketplaceCountries(AMAZON_COUNTRIES)}
                </div>
              </div>
              <div className="column ebay-column">
                <CompanyLogo
                  logo={require("../../../images/home/ebay-logo.svg")}
                />

                <div className="countries-container">
                  {renderMarketplaceCountries(EBAY_COUNTRIES)}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="row secondary-row">
          <RevealOnScroll delay={250}>
            <CompanyLogo
              logo={require("../../../images/home/wish-logo.svg")}
              badge="COMING SOON"
              responsive={true}
            />
          </RevealOnScroll>
          <RevealOnScroll delay={500}>
            <CompanyLogo
              logo={require("../../../images/home/eprice-logo.svg")}
              badge="NUOVO!"
              responsive={true}
            />
          </RevealOnScroll>
          <RevealOnScroll delay={750}>
            <CompanyLogo
              logo={require("../../../images/home/cdiscount-logo.svg")}
              responsive={true}
            />
          </RevealOnScroll>
        </div>

        <h2>
          Collaboriamo con più di 15 marketplace diversi e{" "}
          <mark className="highlighted-text">
            sappiamo scegliere quelli più adatti
          </mark>{" "}
          alle tue esigenze.
        </h2>

        <Link to="/company/">
          <RoundedButton className="company-btn" type="grey">
            AZIENDA
          </RoundedButton>
        </Link>
      </div>
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

MarketPlaceSection.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}

MarketPlaceSection.defaultProps = {}

// ----------------------------------------------------------------------------

const MarketPlaceSectionWithTheme = withTheme(MarketPlaceSection)

// ----------------------------------------------------------------------------

const StyledMarketPlaceSection = styled(MarketPlaceSectionWithTheme)`
  & {
    min-height: 1325px;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;

    > h6 {
      margin-top: 148px;
      margin-bottom: 19.5px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: ${(props) => props.theme.yaleBlue};
    }

    .section-content-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      border-top: 1px solid ${(props) => props.theme.ashGrey};

      width: 100%;
      max-width: 960px;

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;

        .column {
          display: flex;
          flex-direction: column;
        }
      }

      .main-row {
        margin-top: 69.5px;

        .countries-container {
          margin-top: 25.1px;
          display: grid;
          grid-template-columns: 70.47px 70.47px;
          grid-column-gap: 25.4px;
          grid-row-gap: 10px;
          justify-content: center;

          .country-row {
            display: flex;
            flex-direction: row;
            align-items: center;

            > span {
              margin-left: 9.3px;
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
            }
          }
        }
      }

      .secondary-row {
        margin-top: 56.5px;
      }

      h2 {
        margin-top: 100px;
        text-align: center;

        > .highlighted-text {
          background-color: ${(props) => props.theme.pearlAqua};
        }
      }

      .company-btn {
        margin-top: 41px;
      }
    }

    /* MOBILE */

    @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
      margin-bottom: 129px;
      box-sizing: border-box;
      padding: 0 26px;

      .section-content-container {
        align-items: center;

        .main-row {
          flex-direction: column;

          .column {
            align-items: center;
          }

          .ebay-column {
            margin-top: 25px;
          }

          .countries-container {
            margin-top: 25.1px;
            display: grid;
            grid-template-columns: 70.47px 70.47px;
            grid-column-gap: 25.4px;
            grid-row-gap: 10px;
            justify-content: center;

            .country-row {
              > img {
                width: 34.07px;
                height: 34.07px;
              }
            }
          }
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledMarketPlaceSection
