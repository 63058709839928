import React from "react"

import WhyChooseUsSection from "../components/Home/WhyChooseUsSection"
import WhatWeDoSection from "../components/Home/WhatWeDoSection"

import Layout from "../components/_shared/Layout"
import BannerHeroImage from "../components/_shared/BannerHeroImage"
import SEO from "../components/_shared/SEO"
import ContactUsSection from "../components/_shared/ContactUsSection"
import GlookeCallToActionSection from "../components/Home/GlookeCallToActionSection"
import MarketPlaceSection from "../components/Home/MarketPlaceSection"

// ----------------------------------------------------------------------------
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <BannerHeroImage
      image={require("../images/home/hero.png")}
      bannerText={`Vendi in modo intelligente:\nincrementa i guadagni\ndel tuo business online`}
      highlightedText={"i guadagni"}
    />

    <WhatWeDoSection />

    <WhyChooseUsSection image={require("../images/home/choose-us.png")} />

    <MarketPlaceSection />

    <GlookeCallToActionSection />

    <ContactUsSection />
  </Layout>
)

export default IndexPage
