import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import RoundedButton from "../../_shared/RoundedButton"

//------------------------------------------------------------------------------

function GlookeCallToActionSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function goToGlookeWebsite() {
    const glookeUrl = "https://glooke.com"

    typeof window !== "undefined" && window.open(glookeUrl, glookeUrl)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <section className={props.className}>
      <h6>GLOOKE</h6>

      <h2>
        Glooke: oltre 100mila prodotti
        <br />
        gestiti nel nostro marketplace ufficiale.
      </h2>

      <RoundedButton
        className="glooke-btn"
        onClick={goToGlookeWebsite}
        width={216}
      >
        SCOPRI GLOOKE
      </RoundedButton>
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

GlookeCallToActionSection.propTypes = {
  className: PropTypes.string.isRequired,
}

GlookeCallToActionSection.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledGlookeCallToActionSection = styled(GlookeCallToActionSection)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 100vw;
    min-height: 670px;
    max-width: 100vw;
    background-image: url(${require("../../../images/home/glooke-mask.jpg")});
    background-size: cover;
    background-position: center center;

    > h6 {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: ${props => props.theme.jonquil};
    }

    > h2 {
      text-align: center;
      color: ${props => props.theme.white};
    }

    .glooke-btn {
      margin-top: 28px;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledGlookeCallToActionSection
