import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"

//------------------------------------------------------------------------------

function BannerHeroImage(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { bannerText, highlightedText } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function mapTextWithNewLines(text) {
    if (!text.match(/\n/g)) {
      return text
    }

    const replacer = "<br />"

    const newText = text && text.replace(/\n/g, replacer)

    return newText
  }

  function highlightsText(text) {
    if (!highlightedText) {
      return text
    }

    const replacer = "<mark class='highlighted-text'>HIGHLIGHTED TEXT</mark>"

    const newText =
      text &&
      text
        .replace(highlightedText, replacer)
        .replace("HIGHLIGHTED TEXT", highlightedText)

    return newText
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const textWithNewLines = mapTextWithNewLines(bannerText)

  const textWithNewLinesAndHighlighted =
    (highlightedText && highlightsText(textWithNewLines)) || textWithNewLines

  const animationStyle = useSpring({
    opacity: 1,
    transform: `perspective(600px) rotateY(${0}deg)`,
    from: { opacity: 0, transform: `perspective(600px) rotateY(${-90}deg)` },
    config: { duration: 500 },
  })

  return (
    <div className={props.className}>
      <animated.div style={animationStyle}>
        <div className="banner-container">
          <h1
            dangerouslySetInnerHTML={{ __html: textWithNewLinesAndHighlighted }}
          />
        </div>
      </animated.div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

BannerHeroImage.propTypes = {
  className: PropTypes.string.isRequired,
  bannerText: PropTypes.string,
  highlightedText: PropTypes.string,
}

BannerHeroImage.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledBannerHeroImage = styled(BannerHeroImage)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-width: 100vw;
    min-height: 500px;
    max-width: 100vw;

    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center center;

    .banner-container {
      padding: 19px 25px;
      border-radius: 4px;
      background-color: ${props => props.theme.white};
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

      text-align: center;

      > h1 {
        padding: 0 26px;
      }

      .highlighted-text {
        background-color: ${props => props.theme.pearlAqua};
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      min-height: 616px;

      .banner-container {
        box-sizing: border-box;
        padding: 19px 2px;
        margin: 0 20px;

        > h1 {
          font-size: 33px;
          line-height: 38px;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledBannerHeroImage
