import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import RevealOnScroll from "../../_shared/RevealOnScroll"
import RoundedButton from "../../_shared/RoundedButton"
import IconTextInfographic from "../../_shared/IconTextInfographic"

//------------------------------------------------------------------------------

function WhatWeDoSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <img
        className="mobile-header-icn"
        src={require("../../../images/home/icons/performance-icn.svg")}
      />
      <h6>PERCHÉ?</h6>

      <div className="card-container">
        <RevealOnScroll delay={250}>
          <IconTextInfographic
            icon={require("../../../images/home/icons/cart-icn.svg")}
            title={"L’importanza\ndi esserci"}
            description="Investiamo nell’espansione del tuo business nei marketplace per incrementare il volume delle vendite online."
          />
        </RevealOnScroll>
        <RevealOnScroll delay={500}>
          <IconTextInfographic
            icon={require("../../../images/home/icons/performance-icn.svg")}
            title={"Massima\nperformance"}
            description="Utilizziamo la nostra esperienza per liberare le potenzialità del tuo business."
          />
        </RevealOnScroll>
        <RevealOnScroll delay={750}>
          <IconTextInfographic
            icon={require("../../../images/home/icons/laptop-icn.svg")}
            title={"Potenziamento\ndel brand"}
            description="Miglioriamo la presenza e la notorietà del tuo brand online."
          />
        </RevealOnScroll>
      </div>

      <Link to="/services/">
        <RoundedButton className="services-btn" type="blue">
          SERVIZI
        </RoundedButton>
      </Link>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

WhatWeDoSection.propTypes = {
  className: PropTypes.string.isRequired,
}

WhatWeDoSection.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledWhatWeDoSection = styled(WhatWeDoSection)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 664px;

    .mobile-header-icn {
      display: none;
    }

    > h6 {
      margin-top: 85px;
      margin-bottom: 72.2px;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: ${props => props.theme.yaleBlue};
    }

    .card-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 80%;
      max-width: 939px;
    }

    .services-btn {
      margin-top: 20px;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      min-height: unset;

      .mobile-header-icn {
        display: block;

        margin-top: 76.2px;
      }

      > h6 {
        margin-top: 27.7px;
        margin-bottom: 2px;
      }

      .card-container {
        flex-direction: column;
        align-items: center;
        width: 90vw;

        > div {
          padding-top: 22.5px;
          padding-bottom: 12.5px;
          border-bottom: 1px solid ${props => props.theme.ashGrey};
          .icon-container {
            display: none;
          }
        }
      }

      .services-btn {
        margin-top: 37px;
        margin-bottom: 84px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledWhatWeDoSection
