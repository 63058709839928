import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import IconTextInfographic from "../IconTextInfographic"

//------------------------------------------------------------------------------

function IconTextCard(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, ...restProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={className}>
      <IconTextInfographic {...restProps} />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

IconTextCard.propTypes = {
  className: PropTypes.string.isRequired,
}

IconTextCard.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledIconTextCard = styled(IconTextCard)`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 341.41px;

    width: 310px;
    max-width: 90vw;

    width: auto;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);

    > div {
      margin: 0 26px;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledIconTextCard
