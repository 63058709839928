import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ContactUsForm from "./ContactUsForm"

//------------------------------------------------------------------------------

function ContactUsSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <section className={props.className}>
      <h6>CONTATTACI</h6>

      <h2>
        Richiedi una consulenza
        <br />
        professionale. Inizia a incrementare le
        <br />
        tue vendite online.
      </h2>

      <ContactUsForm />
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ContactUsSection.propTypes = {
  className: PropTypes.string.isRequired,
}

ContactUsSection.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledContactUsSection = styled(ContactUsSection)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100vw;

    background: linear-gradient(to bottom left, #00528e, #0b1965);

    > h6 {
      margin-top: 75px;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: ${props => props.theme.jonquil};
    }

    > h2 {
      text-align: center;
      color: ${props => props.theme.white};
      margin-bottom: 55px;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      height: auto;

      > h6 {
        margin-top: 47.1px;
        margin-bottom: 17.9px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: ${props => props.theme.jonquil};
      }

      > h2 {
        text-align: center;
        color: ${props => props.theme.white};
        margin-bottom: 30.4px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledContactUsSection
