import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Carousel from "nuka-carousel"

import whitePrevIcon from "../../../images/_shared/icons/slider-prev-icn.svg"
import whiteNextIcon from "../../../images/_shared/icons/slider-next-icn.svg"
import greyPrevIcon from "../../../images/_shared/icons/slider-prev-grey-icn.svg"
import greyNextIcon from "../../../images/_shared/icons/slider-next-grey-icn.svg"

//------------------------------------------------------------------------------

function SiteSlider(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { slides, renderSlide, theme, height } = props

	// -------------------------------------
	// Hooks (e.g. useState, useMemo ...)
	// -------------------------------------

	const carouselRef = useRef(null)
	// -------------------------------------
	// Effects
	// -------------------------------------

	useEffect(() => {
		if (typeof window !== "undefined") {
			setTimeout(() => {
				carouselRef && carouselRef.current.setDimensions()
			}, 0)
		}
	})

	// -------------------------------------
	// Component functions
	// -------------------------------------

	function renderSlides() {
		return (
			slides &&
			slides.map((slide, index) => {
				return renderSlide && renderSlide(index)
			})
		)
	}

	// -------------------------------------
	// Component local variables
	// -------------------------------------

	const sliderTheme = {
		dark: {
			prevIcn: whitePrevIcon,
			nextIcn: whiteNextIcon,
		},
		light: {
			prevIcn: greyPrevIcon,
			nextIcn: greyNextIcon,
		},
	}

	return (
		<div className={props.className}>
			<Carousel
				ref={carouselRef}
				height={height}
				initialSlideWidth="90vw"
				initialSlideHeight={height}
				renderCenterLeftControls={({ previousSlide }) => (
					<img
						className="control-arrow"
						src={sliderTheme[theme].prevIcn}
						onClick={previousSlide}
					/>
				)}
				renderCenterRightControls={({ nextSlide }) => (
					<img
						className="control-arrow"
						src={sliderTheme[theme].nextIcn}
						onClick={nextSlide}
					/>
				)}
				renderBottomCenterControls={() => {}}
				disableEdgeSwiping={true}
			>
				{renderSlides()}
			</Carousel>
		</div>
	)
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

SiteSlider.propTypes = {
	className: PropTypes.string.isRequired,
	slides: PropTypes.any,
	renderSlide: PropTypes.func,
	theme: PropTypes.string,
	height: PropTypes.any,
}

SiteSlider.defaultProps = {
	theme: "dark",
	height: "auto",
}

// ----------------------------------------------------------------------------

const StyledSiteSlider = styled(SiteSlider)`
	& {
		/* center slide in container */
		.slider-slide {
			> div {
				margin: auto;
			}
		}

		.control-arrow {
			padding: 0 calc(5vw / 2 - 3.5px);
		}
	}
`
// ----------------------------------------------------------------------------

export default StyledSiteSlider
