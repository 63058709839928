import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import { Link } from "gatsby"

import RevealOnScroll from "../../_shared/RevealOnScroll"
import RoundedButton from "../../_shared/RoundedButton"
import IconTextCard from "../../_shared/IconTextCard"
import MobileSiteSlider from "../../_shared/SiteSlider"

//------------------------------------------------------------------------------

const SLIDES = [
  <IconTextCard
    key={"icon-text-card-1"}
    icon={require("../../../images/home/icons/consulting-icn.svg")}
    title="Consulenza dedicata"
    description="Individuiamo la strategia migliore per esprimere il tuo business online."
  />,

  <IconTextCard
    key={"icon-text-card-2"}
    icon={require("../../../images/home/icons/costs-icn.svg")}
    title="Costi sostenibili"
    description="Stabiliamo un costo di gestione proporzionato alla grandezza del catalogo."
  />,

  <IconTextCard
    key={"icon-text-card-3"}
    icon={require("../../../images/home/icons/logistics-icn.svg")}
    title="Logistica snella"
    description="Offriamo soluzioni logistiche compatibili con la tua organizzazione interna."
  />,
]

// ----------------------------------------------------------------------------

function WhyChooseUsSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // ------------------------------------ -
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const defaultIsMobile = matchMobileMediaQuery()

  const [isMobile, setisMobile] = useState(defaultIsMobile)

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowResize)
    }

    return function cleanup() {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleWindowResize() {
    setisMobile(matchMobileMediaQuery())
  }

  function matchMobileMediaQuery() {
    const { theme } = props

    if (typeof window !== "undefined") {
      const mediaQuery = theme.mediaQueries && theme.mediaQueries.mobile

      const matchMediaString = `(max-width: ${mediaQuery})`

      const matchMedia = window.matchMedia(matchMediaString)

      if (matchMedia.matches) {
        return true
      }
    }

    return false
  }

  function renderSlide(index) {
    return SLIDES[index]
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <section className={props.className}>
      <h6>PERCHÉ SCEGLIERCI?</h6>

      <h2>
        Sales Bridge non è un partner
        <br /> qualunque. Scopri tutti i vantaggi.
      </h2>

      {typeof window !== "undefined" && isMobile && (
        <div className="wcu-slider-container">
          <MobileSiteSlider
            slides={SLIDES}
            renderSlide={renderSlide}
            height={341.41}
          />
        </div>
      )}

      {typeof window !== "undefined" && !isMobile && (
        <div className="cards-container">
          <RevealOnScroll delay={100}>{SLIDES[0]}</RevealOnScroll>
          <RevealOnScroll delay={200}>{SLIDES[1]}</RevealOnScroll>
          <RevealOnScroll delay={300}>{SLIDES[2]}</RevealOnScroll>
        </div>
      )}

      <Link to="/contacts/">
        <RoundedButton className="contacts-btn" type="blue">
          CONTATTACI
        </RoundedButton>
      </Link>
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

WhyChooseUsSection.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}

WhyChooseUsSection.defaultProps = {}

// ----------------------------------------------------------------------------

const WhyChooseUsSectionWithTheme = withTheme(WhyChooseUsSection)

// ----------------------------------------------------------------------------

const StyledWhyChooseUsSection = styled(WhyChooseUsSectionWithTheme)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 100vw;
    min-height: 800px;
    max-width: 100vw;

    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center center;

    > h6 {
      margin-top: 99px;
      margin-bottom: 22px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: ${props => props.theme.jonquil};
    }

    > h2 {
      text-align: center;
      color: ${props => props.theme.white};
      margin-bottom: 55px;
    }

    .cards-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 80%;
      max-width: 980px;
    }

    .contacts-btn {
      margin-top: 55.7px;
    }

    .wcu-slider-container {
      width: 100vw;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      padding-bottom: 50px;

      .cards-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding: 0 26px;
        width: 100vw;

        overflow-x: hidden;

        > * {
          margin-bottom: 14px;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledWhyChooseUsSection
