import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import TextInput from "../../TextInput"
import TextArea from "../../TextArea"
import UploadInput from "../../UploadInput"
import RadioButton from "../../RadioButton"
import RoundedButton from "../../RoundedButton"

//------------------------------------------------------------------------------

function ContactUsForm(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const formElement = useRef(null)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPrivacyRadioChecked, setIsPrivacyRadioChecked] = useState(false)
  const [isNewsLetterRadioChecked, setIsNewsLetterRadioChecked] = useState(true)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleSubmit(event) {
    setIsSubmitting(true)
    event.preventDefault()
    const form = formElement.current

    const formData = new FormData(form)

    // add form name for netlify matching
    formData.append("form-name", form.getAttribute("name"))
    formData.append(
      "subject",
      "Nuova richiesta di contatto dal sito web Sales Bridge"
    )

    !isNewsLetterRadioChecked &&
      formData.append("Iscrizione alla newsletter", "No")

    fetch("/", {
      method: "POST",
      body: formData,
    })
      .then(res => {
        setIsSubmitting(false)
        setIsFormSubmitted(true)
      })
      .catch(err => {
        setIsSubmitting(false)
      })
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <form
      className={props.className}
      ref={formElement}
      id="Contact Form"
      name="Contact Form"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input
        type="hidden"
        name="subject"
        value="Nuova richiesta di contatto dal sito web Sales Bridge"
        hidden
      />

      <div className="form-row display-row">
        <TextInput
          name="Nome e cognome"
          placeholder="Nome e cognome"
          required
        />
        <TextInput name="Email" type="email" placeholder="Email" required />
      </div>
      <div className="form-row display-row">
        <TextInput name="Azienda" placeholder="Azienda" required />
        <TextInput
          name="Sito web dell'azienda"
          type="url"
          placeholder="Sito web"
          required
        />
      </div>

      <TextInput
        className="form-row"
        name="Settore merceologico"
        placeholder="Settore merceologico"
        required
      />

      <TextArea
        name="Messaggio"
        className="form-row"
        placeholder="Messaggio"
        required
      />

      <UploadInput
        name="Allegato"
        className="form-row"
        placeholder="Allegato opzionale"
      />

      <div className="radio-btns-section">
        <RadioButton
          className="privacy-radio-btn"
          name="Accettato la Privacy Policy"
          label={
            <span>
              {`Ho letto e accettato la`}&nbsp;
              <Link to="/privacy/">Privacy Policy</Link>
            </span>
          }
          value="Si"
          onChange={event => {
            setIsPrivacyRadioChecked(!isPrivacyRadioChecked)
          }}
          checked={isPrivacyRadioChecked}
          required
        />

        <RadioButton
          className="newsletter-radio-btn"
          name="Iscrizione alla newsletter"
          label={<span>Voglio rimanere aggiornato su Sales Bridge</span>}
          value={isNewsLetterRadioChecked ? "Si" : "No"}
          onChange={event => {
            setIsNewsLetterRadioChecked(!isNewsLetterRadioChecked)
          }}
          checked={isNewsLetterRadioChecked}
        />
      </div>

      <div className="submit-btn-container">
        <RoundedButton
          className={`send-btn${isSubmitting || isFormSubmitted ? " out" : ""}`}
        >
          {!isFormSubmitted
            ? isSubmitting
              ? "INVIO IN CORSO..."
              : "INVIA"
            : "RICHIESTA INVIATA CON SUCCESSO!"}
        </RoundedButton>

        <div className={`submitted-msg${isFormSubmitted ? " visible" : ""}`}>
          <span>MESSAGGIO INVIATO!</span>
        </div>
      </div>
    </form>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ContactUsForm.propTypes = {
  className: PropTypes.string.isRequired,
}

ContactUsForm.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledContactUsForm = styled(ContactUsForm)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;

    width: 634px;
    max-width: 634px;

    .form-row {
      width: 100%;
      margin-bottom: 13px;
    }

    .display-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > * {
        flex: 1;

        :first-child {
          margin-right: 14px;
        }
      }
    }

    .privacy-radio-btn {
      margin-top: 28px;
      min-width: 350px;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;

        color: ${props => props.theme.white};
        font-size: 16px;
        line-height: 24px;

        a {
          text-decoration: none;
          font-weight: 600;
          color: ${props => props.theme.white};
        }
      }
    }

    .newsletter-radio-btn {
      margin-top: 14px;
      min-width: 400px;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;

        color: ${props => props.theme.white};
        font-size: 16px;
        line-height: 24px;
      }
    }

    .submit-btn-container {
      margin-top: 49px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;

      overflow: hidden;

      .send-btn {
        transition: all 0.2s ease-in-out width 0.2s ease-in-out 0.2s;
      }

      .send-btn.out {
        animation: sendButtonSlideOut 0.2s ease-in-out;
        animation-fill-mode: forwards;
      }

      @keyframes sendButtonSlideOut {
        0% {
          transform: translateX(0);
          opacity: 1;
        }

        90% {
          width: auto;
        }
        100% {
          transform: translateX(-200%);
          opacity: 0;
          width: 0;
        }
      }

      .submitted-msg {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 0;
        border: 1px solid transparent;
        border-radius: 25px;
        background-color: ${props => props.theme.white};
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: ${props => props.theme.yaleBlue};

        transition: all 0.2s ease-in-out;
        transform: translateX(100%);
        opacity: 0;
      }

      .submitted-msg.visible {
        transform: translateX(0);
        opacity: 1;
        width: 236px;
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      margin-bottom: 50px;
      width: 100vw;
      padding: 0 26px;
      box-sizing: border-box;
      .display-row {
        flex-direction: column;

        > * {
          flex: unset;

          :first-child {
            margin-bottom: 13px;
            margin-right: 0;
          }
        }
      }

      .radio-btns-section {
        min-width: 100%;
        max-width: 100%;
        overflow: hidden;

        .privacy-radio-btn {
          margin-top: 28px;
          min-width: 100%;

          span {
            display: inline;
            word-break: break-word;
          }
        }

        .newsletter-radio-btn {
          margin-top: 28px;
          min-width: 100%;

          span {
            display: inline;
            word-break: break-word;
          }
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledContactUsForm
