import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function IconTextInfographic(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { title, description } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function mapTitle(text) {
    if (!text.match(/\n/g)) {
      return text
    }

    const replacer = "<br />"

    const newText = text && text.replace(/\n/g, replacer)

    return newText
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const formattedTitle = title && mapTitle(title)
  return (
    <div className={props.className}>
      <div className="icon-container" />
      <h3 dangerouslySetInnerHTML={{ __html: formattedTitle }} />
      <p>{description}</p>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

IconTextInfographic.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
}

IconTextInfographic.defaultProps = {
  icon: null,
  title: "Card title",
  description: "description...",
}

// ----------------------------------------------------------------------------

const StyledIconTextInfographic = styled(IconTextInfographic)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    max-width: 252px;

    > .icon-container {
      margin-bottom: 5px;
      min-height: 160.75px;
      min-width: 100%;
      max-height: 160.75px;
      max-width: 100%;
      background-image: url(${props => `${props.icon}`});
      background-position: center center;
      background-repeat: no-repeat;
    }

    > h3 {
      margin-bottom: 7px;
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      text-align: center;
    }

    > p {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledIconTextInfographic
