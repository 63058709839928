import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

//------------------------------------------------------------------------------

function CompanyLogo(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { badge } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function renderBadge(text) {
    return <p className="badge">{text}</p>
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return <div className={props.className}>{badge && renderBadge(badge)}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CompanyLogo.propTypes = {
  className: PropTypes.string.isRequired,
  badge: PropTypes.text,
}

CompanyLogo.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCompanyLogo = styled(CompanyLogo)`
  & {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    width: 196.58px;
    height: 196.58px;
    border: 0.25px solid ${props => props.theme.logoBorderColor};
    border-radius: 50%;

    background-image: url(${props => props.logo});
    background-size: contain;
    background-position: center center;
    background-origin: content-box;
    background-repeat: no-repeat;

    .badge {
      position: absolute;
      bottom: 7.8px;
      left: 50%;
      transform: translate(-50%);
      box-sizing: border-box;
      padding: 0 16.3px;
      height: 26.74px;
      min-width: 140.45px;

      background-color: ${props => props.theme.charlestonGrey};

      font-size: 14px;
      line-height: 26.74px;
      font-weight: 500;
      color: ${props => props.theme.white};
      text-align: center;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      ${props =>
        props.responsive &&
        css`
          width: 92.68px;
          height: 92.68px;

          .badge {
            position: absolute;
            bottom: 0;
            padding: 0 16.3px;
            height: 17.76px;
            min-width: 97.53px;

            font-size: 8px;
            line-height: 17.76px;
            font-weight: bold;
          }
        `}
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledCompanyLogo
